import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Card, Button } from 'react-bootstrap';
import Select from 'react-select';

import RestClient from 'Services/RestClientService';

const SubmitFormComponent = ({
  currentUserEmail,
  countriesOptionsList,
  repWorkApplication,
  setRepWorkApplication,
  repWorkApplicationError,
  setRepWorkApplicationError,
  isWorkApplicationSubmitted,
  requiredField,
  handleSendRepForm,
}) => {
  const [managersList, setManagerList] = useState([]);

  useEffect(() => {
    RestClient.Get(`staff/managers`).then((response) => {
      let managers = [];
      if (response || response.staffList) {
        managers = response.staffList.map((manager) => {
          return {
            value: manager.staffID,
            label: `${manager.firstName} ${manager.lastName}`,
          };
        });
      }
      managers.push({ value: 'Manager Not in List', label: 'Manager Not in List' });

      setManagerList(managers);
    });
  }, []);

  return (
    <>
      <Card className="mt-1">
        <Card.Header>
          <i className="fa fa-check mr-2" />
          Submit form
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formSignature">
                  <Form.Label>Signature</Form.Label>
                  <Form.Control
                    type="text"
                    value={currentUserEmail}
                    disabled
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repGlobalForm: {
                          ...repWorkApplication.repGlobalForm,
                          signature: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formLocation">
                  <Form.Label>Location {requiredField()}</Form.Label>
                  <Select
                    options={countriesOptionsList}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repGlobalForm: {
                          ...repWorkApplication.repGlobalForm,
                          location: e?.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repGlobalForm: {
                          ...repWorkApplicationError.repGlobalForm,
                          location: '',
                        },
                      });
                    }}
                    value={
                      countriesOptionsList.find((obj) => obj.value === repWorkApplication.repGlobalForm.location) ||
                      null
                    }
                    isClearable
                    isSearchable
                    isDisabled={isWorkApplicationSubmitted}
                  />
                  {repWorkApplicationError.repGlobalForm.location && (
                    <Form.Text className="form-text-red">{repWorkApplicationError.repGlobalForm.location}</Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formManagerName">
                  <Form.Label>Manager name {requiredField()}</Form.Label>
                  <Select
                    options={managersList}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repGlobalForm: {
                          ...repWorkApplication.repGlobalForm,
                          managerName: e?.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repGlobalForm: {
                          ...repWorkApplicationError.repGlobalForm,
                          managerName: '',
                        },
                      });
                    }}
                    value={
                      managersList.find((obj) => obj.value === repWorkApplication.repGlobalForm.managerName) || null
                    }
                    isClearable
                    isSearchable
                    isDisabled={isWorkApplicationSubmitted}
                  />
                  {repWorkApplicationError.repGlobalForm.managerName && (
                    <Form.Text className="form-text-red">{repWorkApplicationError.repGlobalForm.managerName}</Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="formComments">
                  <Form.Label>Any other comments?</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="100 characters max"
                    rows={4}
                    value={repWorkApplication.repGlobalForm.repComments}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repGlobalForm: {
                          ...repWorkApplication.repGlobalForm,
                          repComments: e.target.value,
                        },
                      });
                    }}
                    disabled={isWorkApplicationSubmitted}
                    maxLength={100}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Row className="d-flex flex-row-reverse p-1">
        <Button
          variant="success"
          className="mr-2"
          onClick={handleSendRepForm}
          size="lg"
          disabled={isWorkApplicationSubmitted}
        >
          Submit
        </Button>
      </Row>
    </>
  );
};

export default SubmitFormComponent;
