import React from 'react';
import { Form, Row, Col, Card } from 'react-bootstrap';
import Select from 'react-select';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { DATE_FORMAT_ISO, DATE_NO_DAY_FORMAT_WEB } from 'Constants/dateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';

const roleOptions = [
  { value: 'Childcare Rep', label: 'Childcare Rep' },
  { value: 'Childcare Supervisor', label: 'Childcare Supervisor' },
  { value: 'Operations Support Agent', label: 'Operations Support Agent' },
  { value: 'Ski Rep', label: 'Ski Rep' },
  { value: 'Service Support Rep', label: 'Service Support Rep' },
  { value: 'Team Leader', label: 'Team Leader' },
  { value: 'Operations Support Team Leader', label: 'Operations Support Team Leader' },
  { value: 'Operations Team Leader', label: 'Operations Team Leader' },
  { value: 'Resort Accounts Controller', label: 'Resort Accounts Controller' },
  { value: 'Childcare Team Manager', label: 'Childcare Team Manager' },
  { value: 'Childcare Team Leader', label: 'Childcare Team Leader' },
  { value: 'Team Manager', label: 'Team Manager' },
];

const destinationOptions = [
  { value: 'Andorra', label: 'Andorra' },
  { value: 'Austria', label: 'Austria' },
  { value: 'Bulgaria', label: 'Bulgaria' },
  { value: 'Canada', label: 'Canada' },
  { value: 'Finland', label: 'Finland' },
  { value: 'France', label: 'France' },
  { label: 'Italy', value: 'Italy' },
  { value: 'Norway', label: 'Norway' },
  { value: 'Slovenia', label: 'Slovenia' },
  { value: 'Spain', label: 'Spain' },
  { label: 'Switzerland', value: 'Switzerland' },
];

const comfortableOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
  { value: 'Non-Driver', label: 'Non-Driver' },
];

const dualSoleOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

const RepSkiSkiForm = (props) => {
  const {
    repWorkApplication,
    setRepWorkApplication,
    repWorkApplicationError,
    setRepWorkApplicationError,
    isWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRepWorkApplication({
      ...repWorkApplication,
      repSkiSkiForm: {
        ...repWorkApplication.repSkiSkiForm,
        [name]: value,
      },
    });
    if (repWorkApplicationError[name]) {
      setRepWorkApplicationError({
        ...repWorkApplicationError.repSkiSkiForm,
        repSkiSkiForm: {
          ...repWorkApplicationError.repSkiSkiForm,
          [name]: value,
        },
      });
    }
  };

  const handleInterestChange = (e) => {
    const { value } = e.target;
    console.log('value in handleInterestChange : ', value);
    setRepWorkApplication({
      ...repWorkApplication,
      repSkiSkiForm: {
        ...repWorkApplication.repSkiSkiForm,
        expressingInterest: value,
      },
    });

    if (repWorkApplicationError.repSkiSkiForm.expressingInterest) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: {
          ...repWorkApplicationError.repSkiSkiForm,
          expressingInterest: null,
        },
      });
    }
  };

  const handleSelectChange = (selectedOption, field) => {
    setRepWorkApplication({
      ...repWorkApplication,
      repSkiSkiForm: {
        ...repWorkApplication.repSkiSkiForm,
        [field]: selectedOption ? selectedOption.value : '',
      },
    });
    if (repWorkApplicationError.repSkiSkiForm[field]) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: {
          ...repWorkApplicationError.repSkiSkiForm,
          [field]: '',
        },
      });
    }
  };

  const handleDatetimeChange = (date, field) => {
    let dateString = '';
    if (typeof date === 'string') {
      dateString = date;
    } else {
      dateString = date.format('YYYY-MM');
    }
    setRepWorkApplication({
      ...repWorkApplication,
      repSkiSkiForm: {
        ...repWorkApplication.repSkiSkiForm,
        [field]: dateString,
      },
    });
    if (repWorkApplicationError[field]) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: {
          ...repWorkApplicationError.repSkiSkiForm,
          [field]: '',
        },
      });
    }
  };

  const getSelectValue = (field, options) => {
    const fieldValue = repWorkApplication.repSkiSkiForm[field];
    return options.find((opt) => opt.value === fieldValue) || null;
  };

  const showFurtherQuestions = repWorkApplication.repSkiSkiForm.expressingInterest === 'Yes';

  const validMonth = (current) => {
    return current.isAfter(Datetime.moment().subtract(1, 'month'));
  };

  return (
    <Card className="mt-1">
      <Card.Body>
        <Form>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="expressingInterest">
                <Form.Label>1. Are you expressing your interest for a W2526? {requiredField()}</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    name="expressingInterest"
                    value="Yes"
                    onChange={handleInterestChange}
                    checked={repWorkApplication.repSkiSkiForm.expressingInterest === 'Yes'}
                    disabled={isWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    name="expressingInterest"
                    value="No"
                    onChange={handleInterestChange}
                    checked={repWorkApplication.repSkiSkiForm.expressingInterest === 'No'}
                    disabled={isWorkApplicationSubmitted}
                  />
                </div>
                {repWorkApplicationError.repSkiSkiForm.expressingInterest && (
                  <Form.Control.Feedback type="invalid" className="d-block">
                    {repWorkApplicationError.repSkiSkiForm.expressingInterest}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          {repWorkApplication.repSkiSkiForm.expressingInterest === 'No' && (
            <Row>
              <Col>
                <p className="text-muted">
                  Thank You, no further information is required from you at this time and you are not required to submit
                  the form.
                </p>
              </Col>
            </Row>
          )}

          {showFurtherQuestions && (
            <>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="role">
                    <Form.Label>2. Which Role are you interested in? {requiredField()}</Form.Label>
                    <Select
                      name="role"
                      value={getSelectValue('role', roleOptions)}
                      onChange={(option) => handleSelectChange(option, 'role')}
                      options={roleOptions}
                      error={!!repWorkApplicationError.repSkiSkiForm.role}
                      placeholder="Select Role"
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.role && (
                      <div className="invalid-feedback d-block">{repWorkApplicationError.repSkiSkiForm.role}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="firstDestination">
                    <Form.Label>3. First Destination Preference? {requiredField()}</Form.Label>
                    <Select
                      name="firstDestination"
                      value={getSelectValue('firstDestination', destinationOptions)}
                      onChange={(option) => handleSelectChange(option, 'firstDestination')}
                      options={destinationOptions}
                      error={!!repWorkApplicationError.repSkiSkiForm.firstDestination}
                      placeholder="Select Destination"
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.firstDestination && (
                      <div className="invalid-feedback d-block">
                        {repWorkApplicationError.repSkiSkiForm.firstDestination}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="secondDestination">
                    <Form.Label>4. Second Destination Preference? {requiredField()}</Form.Label>
                    <Select
                      name="secondDestination"
                      value={getSelectValue('secondDestination', destinationOptions)}
                      onChange={(option) => handleSelectChange(option, 'secondDestination')}
                      options={destinationOptions}
                      error={!!repWorkApplicationError.repSkiSkiForm.secondDestination}
                      placeholder="Select Destination"
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.secondDestination && (
                      <div className="invalid-feedback d-block">
                        {repWorkApplicationError.repSkiSkiForm.secondDestination}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="comfortableDriving">
                    <Form.Label>5. Are you comfortable to drive in snow? {requiredField()}</Form.Label>
                    <Select
                      name="comfortableDriving"
                      value={getSelectValue('comfortableDriving', comfortableOptions)}
                      onChange={(option) => handleSelectChange(option, 'comfortableDriving')}
                      options={comfortableOptions}
                      error={!!repWorkApplicationError.repSkiSkiForm.comfortableDriving}
                      placeholder="Select Option"
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.comfortableDriving && (
                      <div className="invalid-feedback d-block">
                        {repWorkApplicationError.repSkiSkiForm.comfortableDriving}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="availableFrom">
                    <Form.Label>6. What date are you available from? (minimum 6 months) {requiredField()}</Form.Label>
                    <Datetime
                      inputProps={{
                        placeholder: 'Select Month',
                        name: 'availableFrom',
                        disabled: isWorkApplicationSubmitted,
                      }}
                      dateFormat="MM/YYYY"
                      timeFormat={false}
                      viewMode="months"
                      isValidDate={validMonth}
                      value={DateTimeFormater(
                        repWorkApplication.repSkiSkiForm.availableFrom,
                        DATE_FORMAT_ISO,
                        DATE_NO_DAY_FORMAT_WEB
                      )}
                      onChange={(date) => handleDatetimeChange(date, 'availableFrom')}
                    />
                    {repWorkApplicationError.repSkiSkiForm.availableFrom && (
                      <div className="invalid-feedback d-block">
                        {repWorkApplicationError.repSkiSkiForm.availableFrom}
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="availableTo">
                    <Form.Label>7. What date are you available to? (minimum 6 months) {requiredField()}</Form.Label>
                    <Datetime
                      inputProps={{
                        placeholder: 'Select Month',
                        name: 'availableTo',
                        disabled: isWorkApplicationSubmitted,
                      }}
                      dateFormat="MM/YYYY"
                      timeFormat={false}
                      viewMode="months"
                      isValidDate={validMonth}
                      value={DateTimeFormater(
                        repWorkApplication.repSkiSkiForm.availableTo,
                        DATE_FORMAT_ISO,
                        DATE_NO_DAY_FORMAT_WEB
                      )}
                      onChange={(date) => handleDatetimeChange(date, 'availableTo')}
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.availableTo && (
                      <div className="invalid-feedback d-block">
                        {repWorkApplicationError.repSkiSkiForm.availableTo}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="dualRole">
                    <Form.Label>8. Are you interested in a Dual Rep Role? {requiredField()}</Form.Label>
                    <Select
                      name="dualRole"
                      value={getSelectValue('dualRole', dualSoleOptions)}
                      onChange={(option) => handleSelectChange(option, 'dualRole')}
                      options={dualSoleOptions}
                      error={!!repWorkApplicationError.repSkiSkiForm.dualRole}
                      placeholder="Select Option"
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.dualRole && (
                      <div className="invalid-feedback d-block">{repWorkApplicationError.repSkiSkiForm.dualRole}</div>
                    )}
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="soleRole">
                    <Form.Label>9. Are you interested in a Sole Rep Role? {requiredField()}</Form.Label>
                    <Select
                      name="soleRole"
                      value={getSelectValue('soleRole', dualSoleOptions)}
                      onChange={(option) => handleSelectChange(option, 'soleRole')}
                      options={dualSoleOptions}
                      error={!!repWorkApplicationError.repSkiSkiForm.soleRole}
                      placeholder="Select Option"
                      isDisabled={isWorkApplicationSubmitted}
                    />
                    {repWorkApplicationError.repSkiSkiForm.soleRole && (
                      <div className="invalid-feedback d-block">{repWorkApplicationError.repSkiSkiForm.soleRole}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group controlId="relationshipDeclaration">
                    <Form.Label>10. Relationship Declaration (Enter Name as per Passport):</Form.Label>
                    <Form.Control
                      type="text"
                      name="relationshipDeclaration"
                      placeholder="Enter Name"
                      maxLength={50}
                      value={repWorkApplication.repSkiSkiForm.relationshipDeclaration || ''}
                      onChange={handleChange}
                      disabled={isWorkApplicationSubmitted}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default RepSkiSkiForm;
