import React from 'react';
import { Card, Form, Col, Row } from 'react-bootstrap';

const ManagerSkiSkiCommentsForm = (props) => {
  const {
    managerWorkApplication,
    setManagerWorkApplication,
    managerWorkApplicationError,
    setManagerWorkApplicationError,
    isManagerWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const cardStyle = {
    marginTop: '0.5%',
  };

  const handleRadioChange = (field, value) => {
    setManagerWorkApplication({
      ...managerWorkApplication,
      managerSkiSkiForm: {
        ...managerWorkApplication.managerSkiSkiForm,
        [field]: value,
      },
    });
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerSkiSkiForm: {
        [field]: null,
      },
    });
  };

  const handleTextChange = (field, value) => {
    setManagerWorkApplication({
      ...managerWorkApplication,
      managerSkiSkiForm: {
        ...managerWorkApplication.managerSkiSkiForm,
        [field]: value,
      },
    });
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerSkiSkiForm: {
        [field]: null,
      },
    });
  };

  const showFurtherQuestions = managerWorkApplication.managerSkiSkiForm?.supportPreferences === 'Yes';

  return (
    <Card style={cardStyle}>
      <Card.Header>
        <i className="fa fa-comments-o mr-2" />
        Manager questionary
      </Card.Header>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Do you support the preferences selected? {requiredField()}</Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    onChange={() => handleRadioChange('supportPreferences', 'Yes')}
                    checked={managerWorkApplication.managerSkiSkiForm?.supportPreferences === 'Yes'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    onChange={() => handleRadioChange('supportPreferences', 'No')}
                    checked={managerWorkApplication.managerSkiSkiForm?.supportPreferences === 'No'}
                    disabled={isManagerWorkApplicationSubmitted}
                  />
                  {managerWorkApplicationError.managerSkiSkiForm?.supportPreferences && (
                    <Form.Text className="form-text-red">
                      {managerWorkApplicationError.managerSkiSkiForm?.supportPreferences}
                    </Form.Text>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Form.Row>

          {!showFurtherQuestions && (
            <Row>
              <Col>
                <p className="text-muted">
                  Thank you for providing your feedback, please ensure that you have this conversation with the
                  individual and we will not take their expression of interest form further.
                </p>
              </Col>
            </Row>
          )}
          {showFurtherQuestions && (
            <>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Are they in accelerate? {requiredField()}</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        value="Yes"
                        onChange={() => handleRadioChange('inAccelerate', 'Yes')}
                        checked={managerWorkApplication.managerSkiSkiForm?.inAccelerate === 'Yes'}
                        disabled={isManagerWorkApplicationSubmitted}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        value="No"
                        onChange={() => handleRadioChange('inAccelerate', 'No')}
                        checked={managerWorkApplication.managerSkiSkiForm?.inAccelerate === 'No'}
                        disabled={isManagerWorkApplicationSubmitted}
                      />
                      {managerWorkApplicationError.managerSkiSkiForm?.inAccelerate && (
                        <Form.Text className="form-text-red">
                          {managerWorkApplicationError.managerSkiSkiForm?.inAccelerate}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Any Disciplinary or Performance Management in Process? {requiredField()}</Form.Label>
                  <Row>
                    <Col>
                      <Form.Check
                        inline
                        label="Yes"
                        type="radio"
                        value="Yes"
                        onChange={() => handleRadioChange('disciplinaryOrPerformanceManagement', 'Yes')}
                        checked={
                          managerWorkApplication.managerSkiSkiForm?.disciplinaryOrPerformanceManagement === 'Yes'
                        }
                        disabled={isManagerWorkApplicationSubmitted}
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        value="No"
                        onChange={() => handleRadioChange('disciplinaryOrPerformanceManagement', 'No')}
                        checked={managerWorkApplication.managerSkiSkiForm?.disciplinaryOrPerformanceManagement === 'No'}
                        disabled={isManagerWorkApplicationSubmitted}
                      />
                      {managerWorkApplicationError.managerSkiSkiForm?.disciplinaryOrPerformanceManagement && (
                        <Form.Text className="form-text-red">
                          {managerWorkApplicationError.managerSkiSkiForm?.disciplinaryOrPerformanceManagement}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>Great Place to Grow Rating? {requiredField()}</Form.Label>
                  <Row>
                    <Col>
                      {[1, 2, 3, 4, 5].map((rating) => (
                        <Form.Check
                          inline
                          key={rating}
                          label={rating}
                          type="radio"
                          value={rating}
                          onChange={() => handleRadioChange('greatPlaceToGrowRating', rating)}
                          checked={managerWorkApplication.managerSkiSkiForm?.greatPlaceToGrowRating === rating}
                          disabled={isManagerWorkApplicationSubmitted}
                        />
                      ))}
                      {managerWorkApplicationError.managerSkiSkiForm?.greatPlaceToGrowRating && (
                        <Form.Text className="form-text-red">
                          {managerWorkApplicationError.managerSkiSkiForm?.greatPlaceToGrowRating}
                        </Form.Text>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </Form.Row>
            </>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ManagerSkiSkiCommentsForm;
