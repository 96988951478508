import React, { useState, useEffect } from 'react';
import { Row, Alert } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { DATETIME_FORMAT_WEB } from 'Constants/dateFormatConstants';
import RepBeachForm from 'Components/WorkApplicationForms/RepBeachForm';
import RepSummerForm from 'Components/WorkApplicationForms/RepSummerForm';
import RepWinterForm from 'Components/WorkApplicationForms/RepWinterForm';
import RepLongHaulForm from 'Components/WorkApplicationForms/RepLongHaulForm';
import RepSkiSkiForm from 'Components/WorkApplicationForms/RepSkiSkiForm';
import SubmitFormComponent from 'Components/SubmitFormComponent';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import RestClient from 'Services/RestClientService';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import { RepFormValidation } from 'Components/WorkApplicationForms/FormValidation/RepFormValidation';
import RepCrystalSkiForm from 'Components/WorkApplicationForms/RepCrystalSkiForm';
import { initialRepWorkApplicationState, initialRepWorkApplicationErrorState } from './initialStates';

const RepWorkApplicationView = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.currentUser.email);
  const tppSettings = useSelector((state) => state.settings);

  const jobFamiliesOptionsList = useSelector((state) => state.masterData.jobFamilies);
  const jobTitlesOptionsList = useSelector((state) => state.masterData.jobTitles);
  const jobFamiliesWork = useSelector((state) => state.settings.jobFamiliesWork);
  const countriesOptionsList = useSelector((state) => state.masterData.countries);

  const [assignmentsList, setAssignmentsList] = useState([]);
  const [employee, setEmployee] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isCrystalSki, setIsCrystalSki] = useState(false);
  const [isSkiSki, setIsSkiSki] = useState(true);

  const [repWorkApplication, setRepWorkApplication] = useState(initialRepWorkApplicationState);
  const [repWorkApplicationError, setRepWorkApplicationError] = useState(initialRepWorkApplicationErrorState);

  const [isWorkApplicationSubmitted, setIsWorkApplicationSubmitted] = useState(false);
  const [refreshParentComponent, setRefreshParentComponent] = useState(1);

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '0.625rem' }}>(required)</small>;
  };

  useEffect(() => {
    if (!currentUserEmail) return;
    RestClient.Get(`staff/getbyemail/${currentUserEmail}`).then((staffInfo) => {
      if (!staffInfo) return;
      setEmployee(staffInfo);
    });
  }, [currentUserEmail]);

  useEffect(() => {
    if (!employee || !employee.staffID) return;
    RestClient.Get(`positionAssign/byStaffId/${employee.staffID}`).then((response) => {
      if (!response || !response.positionAssignList) return;

      let filteredAssignments = response.positionAssignList;

      if (jobFamiliesWork !== '') {
        filteredAssignments = filteredAssignments.filter((assign) => {
          return jobFamiliesWork.split(',').some((element) => element.toLowerCase() === assign.jobFamily.toLowerCase());
        });

        // TODO : add again this after this campaing
        // var validJobFamilies = tppSettings.jobFamiliesWork
        //   .split(',')
        //   .map((jobFamily) => jobFamily.trim().toLowerCase());
        // setIsCrystalSki(validJobFamilies.some((assign) => assign.toLowerCase().includes('crystal ski')));
      }

      setAssignmentsList(filteredAssignments);
    });
  }, [employee, refreshParentComponent]);

  useEffect(() => {
    if (!employee || !employee.staffID) return;

    RestClient.Get(`workApplication/rep/${employee.staffID}/${tppSettings.curSeason}/${isCrystalSki}/${isSkiSki}`)
      .then((response) => {
        if (response.status && !response.repWorkApplication) {
          setRepWorkApplication({
            repGlobalForm: {},
            repSummerForm: {},
            repWinterForm: {},
            repLongHaulForm: {},
            repBeachForm: {},
            repCrystalSkiForm: {},
            repSkiSkiForm: {},
          });
        } else if (
          response.status &&
          response.repWorkApplication &&
          ((response.repSummerForm && response.repWinterForm) ||
            response.repLongHaulForm ||
            response.repCrystalSkiForm ||
            response.repSkiSkiForm)
        ) {
          setRepWorkApplication({
            repGlobalForm: response.repWorkApplication,
            repSummerForm: response.repSummerForm,
            repWinterForm: response.repWinterForm,
            repLongHaulForm: response.repLongHaulForm,
            repBeachForm: response.repBeachForm,
            repCrystalSkiForm: response.repCrystalSkiForm,
            repSkiSkiForm: response.repSkiSkiForm,
          });
          setIsWorkApplicationSubmitted(true);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [employee, refreshParentComponent, isCrystalSki]);

  const handleSendRepForm = () => {
    if (
      RepFormValidation(
        repWorkApplication,
        repWorkApplicationError,
        setRepWorkApplicationError,
        tppSettings.isLongHaul,
        isCrystalSki,
        isSkiSki
      )
    ) {
      repWorkApplication.repGlobalForm.staffId = employee.staffID;
      repWorkApplication.repGlobalForm.currentSeason = tppSettings.curSeason;
      repWorkApplication.repGlobalForm.nextSeason = tppSettings.nextSeason;
      repWorkApplication.repGlobalForm.signature = currentUserEmail;
      repWorkApplication.isLongHaul = tppSettings.isLongHaul;
      repWorkApplication.isCrystalSki = isCrystalSki;

      repWorkApplication.isSkiSki = isSkiSki;

      dispatch(setLoader(true));
      RestClient.Post(`workApplication/rep/`, repWorkApplication)
        .then((response) => {
          if (!response) return;
          if (response.status) {
            setRefreshParentComponent(Math.random());
          } else {
            toastr.error('Error', response.message);
          }
        })
        .finally(() => {
          dispatch(setLoader(false));
        });
    } else {
      toastr.warning('Form missing data', 'Please fill out the required fields');
      return;
    }
  };

  useEffect(() => {
    // console.log(repWorkApplication);
  }, [repWorkApplication]);

  // pre check variables
  let hasAssignments = assignmentsList?.length > 0;

  return (
    <div className="container-fluid">
      <h1 style={{ marginTop: '0.5%' }}>Work Application Form</h1>

      {!hasAssignments ? (
        <>
          <hr style={{ marginTop: '1%' }}></hr>

          <Row className="justify-content-center text-center mt-1">
            <h4>
              <i className="fa fa-search" /> Can´t find any assignment for you
            </h4>
          </Row>
          <Row className="justify-content-center text-center mt-1">
            <small>Seems like you can´t apply at the moment. Try later !.</small>
          </Row>
        </>
      ) : (
        <>
          {/*Show the correct alert  */}
          {isWorkApplicationSubmitted ? (
            <Alert variant="success" className="mt-1">
              <Alert.Heading>Thank You !</Alert.Heading>
              <p>
                We´re happy to let you know that we´ve received your application with your placement preferences. Our
                goal is to match you with the best positions that fit your requests, while also considering our business
                needs. You’ll receive communication about Placements once they become available, directly from your Team
                Manager.
              </p>
              <strong>Your Placement Team</strong>

              <hr />
              <p className="mb-0">
                Your application was received on{' '}
                <strong>
                  {DateTimeFormater(repWorkApplication.repGlobalForm.timestamp, null, DATETIME_FORMAT_WEB)}
                </strong>{' '}
                and was saved with id: <strong>{repWorkApplication.repGlobalForm.id}</strong>
              </p>
            </Alert>
          ) : (
            <div hidden={!hasAssignments}>
              <Alert variant="warning" className="mt-1">
                <Alert.Heading>Remember!</Alert.Heading>
                Please ensure that you accurately fill out the application form, as changes cannot be made once
                submitted!
              </Alert>
            </div>
          )}

          {tppSettings.isLongHaul ? (
            <RepLongHaulForm
              repWorkApplication={repWorkApplication}
              setRepWorkApplication={setRepWorkApplication}
              repWorkApplicationError={repWorkApplicationError}
              isWorkApplicationSubmitted={isWorkApplicationSubmitted}
            />
          ) : isCrystalSki ? (
            <RepCrystalSkiForm
              repWorkApplication={repWorkApplication}
              setRepWorkApplication={setRepWorkApplication}
              repWorkApplicationError={repWorkApplicationError}
              setRepWorkApplicationError={setRepWorkApplicationError}
              isWorkApplicationSubmitted={isWorkApplicationSubmitted}
            />
          ) : isSkiSki ? (
            <RepSkiSkiForm
              repWorkApplication={repWorkApplication}
              setRepWorkApplication={setRepWorkApplication}
              repWorkApplicationError={repWorkApplicationError}
              setRepWorkApplicationError={setRepWorkApplicationError}
              isWorkApplicationSubmitted={isWorkApplicationSubmitted}
            />
          ) : (
            <>
              <RepBeachForm
                repWorkApplication={repWorkApplication}
                setRepWorkApplication={setRepWorkApplication}
                repWorkApplicationError={repWorkApplicationError}
                setRepWorkApplicationError={setRepWorkApplicationError}
                isWorkApplicationSubmitted={isWorkApplicationSubmitted}
              />

              <RepWinterForm
                jobFamiliesOptionsList={jobFamiliesOptionsList}
                jobTitlesOptionsList={jobTitlesOptionsList}
                repWorkApplication={repWorkApplication}
                setRepWorkApplication={setRepWorkApplication}
                repWorkApplicationError={repWorkApplicationError}
                setRepWorkApplicationError={setRepWorkApplicationError}
                isWorkApplicationSubmitted={isWorkApplicationSubmitted}
              />

              {repWorkApplication.repWinterForm.preferencesFor !==
                'I do not wish to work in winter and plan to resign at the end of summer' && (
                <RepSummerForm
                  jobFamiliesOptionsList={jobFamiliesOptionsList}
                  jobTitlesOptionsList={jobTitlesOptionsList}
                  repWorkApplication={repWorkApplication}
                  setRepWorkApplication={setRepWorkApplication}
                  repWorkApplicationError={repWorkApplicationError}
                  setRepWorkApplicationError={setRepWorkApplicationError}
                  isWorkApplicationSubmitted={isWorkApplicationSubmitted}
                />
              )}
            </>
          )}

          <SubmitFormComponent
            currentUserEmail={currentUserEmail}
            countriesOptionsList={countriesOptionsList}
            repWorkApplication={repWorkApplication}
            setRepWorkApplication={setRepWorkApplication}
            repWorkApplicationError={repWorkApplicationError}
            setRepWorkApplicationError={setRepWorkApplicationError}
            isWorkApplicationSubmitted={isWorkApplicationSubmitted}
            requiredField={requiredField}
            handleSendRepForm={handleSendRepForm}
          />
        </>
      )}
    </div>
  );
};

export default RepWorkApplicationView;
