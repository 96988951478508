import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Row, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import RestClient from 'Services/RestClientService';
import ManagerWorkApplicationForm from 'Views/WorkApplication/ManagerWorkApplicationForm';

const ManageRepView = () => {
  const destinationSelectOption = useSelector((state) => state.masterData.destinations);

  const [selectedDestination, setSelectedDestination] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [staffByDestinationOptionList, setStaffByDestinationOptionList] = useState([]);

  const [refreshParentComponent, setRefreshParentComponent] = useState(1);

  const getStaffByDestination = (destination) => {
    RestClient.Get(`staff/getbyDestination/${destination}`).then((response) => {
      if (!response || !response.staffList) return;
      var staffDestinationOptionList = response.staffList.map((staff) => {
        return {
          value: staff.staffID,
          label: `${staff.firstName}, ${staff.lastName}`,
        };
      });
      setStaffByDestinationOptionList(staffDestinationOptionList);
    });
  };

  useEffect(() => {
    if (selectedRep) {
      setRefreshParentComponent((prev) => prev + 1);
    }
  }, [selectedRep]);

  return (
    <Container fluid>
      <h1>Manage reps by destination</h1>
      <Card>
        <Card.Header>Rep selection by destination</Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="formDestination">
                  <Form.Label>Destination</Form.Label>
                  <Select
                    isClearable
                    options={destinationSelectOption}
                    onChange={(e) => {
                      setSelectedDestination(e?.value);
                      getStaffByDestination(e?.value);
                      setSelectedRep(null);
                    }}
                    value={destinationSelectOption.find((option) => option.value === selectedDestination)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="formRep">
                  <Form.Label>Rep</Form.Label>
                  <Select
                    isClearable
                    isDisabled={!selectedDestination}
                    options={staffByDestinationOptionList}
                    onChange={(e) => {
                      setSelectedRep(e?.value);
                    }}
                    value={staffByDestinationOptionList?.find((option) => option.value === selectedRep) || null}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {selectedRep && (
        <ManagerWorkApplicationForm
          staffId={selectedRep}
          setRefreshParentComponent={setRefreshParentComponent}
          refreshParentComponent={refreshParentComponent}
        />
      )}
    </Container>
  );
};

export default ManageRepView;
