import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import RestClient from 'Services/RestClientService';

const TppNavBar = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const settings = useSelector((state) => state.settings);
  // console.log(currentUser);

  const checkActiveNavBarLink = (activeLink) => {
    if (window.location.pathname.includes(activeLink)) return true;
    return false;
  };

  const dispatch = useDispatch();

  const [employee, setEmployee] = useState({});

  useEffect(() => {
    if (!currentUser.email) return;
    dispatch(setLoader(true));
    RestClient.Get(`staff/getbyemail/${currentUser.email}`)
      .then((staffInfo) => {
        if (!staffInfo) return;
        setEmployee((prev) => {
          return { ...prev, ...staffInfo };
        });
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  }, [currentUser]);

  const checkVisibilityOfNavBarLink = (tabOpen) => {
    let result = false;

    const isSettingsDiamondPin = settings.isDiamondPin;
    const isProfileDiamonPin = employee.isDiamondPin;

    if (isSettingsDiamondPin && isProfileDiamonPin && tabOpen === 'Yes') {
      result = true;
    }

    return result;
  };

  return (
    <Nav className="tpp-navbar">
      <Nav.Item>
        <Nav.Link href="/mtp/profile" active={checkActiveNavBarLink('/mtp/profile')}>
          Profile
        </Nav.Link>
      </Nav.Item>

      {(checkVisibilityOfNavBarLink(settings.applyOpen) ||
        (settings.applyOpen === 'Yes' && !settings.isDiamondPin)) && (
        <Nav.Item>
          <Nav.Link href="/mtp/rep-work-application" active={checkActiveNavBarLink('/mtp/rep-work-application')}>
            Application Form
          </Nav.Link>
        </Nav.Item>
      )}

      {(checkVisibilityOfNavBarLink(settings.acceptDeclineOpen) ||
        (settings.acceptDeclineOpen === 'Yes' && !settings.isDiamondPin)) && (
        <Nav.Item>
          <Nav.Link href="/mtp/assignments" active={checkActiveNavBarLink('/mtp/assignments')}>
            Assignments
          </Nav.Link>
        </Nav.Item>
      )}

      {currentUser.isManager && settings.commentsOpen === 'Yes' && (
        <Nav.Item>
          <Nav.Link href="/mtp/manage-rep" active={checkActiveNavBarLink('/mtp/manage-rep')}>
            Manage rep
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  );
};

export default TppNavBar;
