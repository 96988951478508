export const initialRepWorkApplicationState = {
  repGlobalForm: {
    signature: '',
    location: '',
    repComments: '',
    managerName: '',
  },
  repSummerForm: {
    preferencesFor: '',
    preferenceStartMonth: '',
    preferenceEndMonth: '',
    firstChoiceDestination: '',
    firstChoiceJobTitle: '',
    firstChoiceJobFamily: '',
    secondChoiceDestination: '',
    secondChoiceJobTitle: '',
    secondChoiceJobFamily: '',
    thirdChoiceDestination: '',
    thirdChoiceJobTitle: '',
    thirdChoiceJobFamily: '',
    fourthChoiceDestination: '',
    fourthChoiceJobTitle: '',
    fourthChoiceJobFamily: '',
    mostImportant: '',
    accelerateDevelopmentProgram: '',
    willingCompleteVisaApplication: '',
    fullPartnerName: '',
    considerChangeRole: '',
    considerChangeRoleJobTitles: '',
    expiringVisaDate: '',
    customerNationalities: [],
  },
  repWinterForm: {
    preferencesFor: '',
    preferenceStartMonth: '',
    preferenceEndMonth: '',
    firstChoiceDestination: '',
    firstChoiceJobTitle: '',
    firstChoiceJobFamily: '',
    secondChoiceDestination: '',
    secondChoiceJobTitle: '',
    secondChoiceJobFamily: '',
    thirdChoiceDestination: '',
    thirdChoiceJobTitle: '',
    thirdChoiceJobFamily: '',
    fourthChoiceDestination: '',
    fourthChoiceJobTitle: '',
    fourthChoiceJobFamily: '',
    mostImportant: '',
    accelerateDevelopmentProgram: '',
    willingCompleteVisaApplication: '',
    fullPartnerName: '',
    considerChangeRole: '',
    considerChangeRoleJobTitles: '',
    expiringVisaDate: '',
    customerNationalities: [],
  },
  repLongHaulForm: {
    confortableSourceMarket: [],
    passportExpiry: '',
    currentJobFamily: '',
    currentJobTitle: '',
    guestType: [],
    internationalConcept: [],
    nationalConcept: [],
    isWillingWorkOneYear: false,
    isWorkedInLongHaul: false,
    isWorkedInLongHaulYes: '',
    destinationOne: '',
    destinationTwo: '',
    destinationThree: '',
    destinationFour: '',
    whyYouSuitable: '',
  },
  repBeachForm: {
    currentJobTitle: '',
    currentJobFamily: '',
    guestType: [],
    internationalConcept: [],
    nationalConcept: [],
    accelerateDevelopmentProgram: '',
  },
  repCrystalSkiForm: {
    summer2025Placement: '',
    interestedInPlacement: '',
    roleInterested: '',
    availableFrom: '',
    availableTo: '',
    interestReason: '',
    teamManager: '',
  },
  repSkiSkiForm: {
    expressingInterest: '', // "Yes" or "No"
    role: '', // e.g., "Childcare Rep", "Team Leader", etc.
    firstDestination: '', // e.g., "Andorra", "Spain", etc.
    secondDestination: '', // e.g., "Austria", "Canada", etc.
    comfortableDriving: '', // "Yes", "No" or "Non-Driver"
    availableFrom: '', // Format "YYYY-MM"
    availableTo: '', // Format "YYYY-MM"
    dualRole: '', // "Yes" or "No"
    soleRole: '', // "Yes" or "No"
    relationshipDeclaration: '', // Name as per passport, max 50 characters
  },
};

export const initialRepWorkApplicationErrorState = {
  repGlobalForm: {
    staffId: '',
    currentSeason: '',
    nextSeason: '',
    signature: '',
    location: '',
    managerName: '',
  },
  repSummerForm: {
    preferencesFor: '',
    preferenceStartMonth: '',
    preferenceEndMonth: '',
    firstChoiceDestination: '',
    firstChoiceJobTitle: '',
    firstChoiceJobFamily: '',
    secondChoiceDestination: '',
    secondChoiceJobTitle: '',
    secondChoiceJobFamily: '',
    thirdChoiceDestination: '',
    thirdChoiceJobTitle: '',
    thirdChoiceJobFamily: '',
    fourthChoiceDestination: '',
    fourthChoiceJobTitle: '',
    fourthChoiceJobFamily: '',
    mostImportant: '',
    accelerateDevelopmentProgram: '',
    willingCompleteVisaApplication: '',
    fullPartnerName: '',
    considerChangeRole: '',
    considerChangeRoleJobTitles: '',
    expiringVisaDate: '',
    customerNationalities: '',
  },
  repWinterForm: {
    preferencesFor: '',
    preferenceStartMonth: '',
    preferenceEndMonth: '',
    firstChoiceDestination: '',
    firstChoiceJobTitle: '',
    firstChoiceJobFamily: '',
    secondChoiceDestination: '',
    secondChoiceJobTitle: '',
    secondChoiceJobFamily: '',
    thirdChoiceDestination: '',
    thirdChoiceJobTitle: '',
    thirdChoiceJobFamily: '',
    fourthChoiceDestination: '',
    fourthChoiceJobTitle: '',
    fourthChoiceJobFamily: '',
    mostImportant: '',
    accelerateDevelopmentProgram: '',
    willingCompleteVisaApplication: '',
    fullPartnerName: '',
    considerChangeRole: '',
    considerChangeRoleJobTitles: '',
    expiringVisaDate: '',
    customerNationalities: '',
  },
  repLongHaulForm: {
    confortableSourceMarket: '',
    passportExpiry: '',
    currentJobFamily: '',
    currentJobTitle: '',
    guestType: '',
    internationalConcept: '',
    nationalConcept: '',
    isWillingWorkOneYear: false,
    isWorkedInLongHaul: false,
    isWorkedInLongHaulYes: '',
    destinationOne: '',
    destinationTwo: '',
    destinationThree: '',
    destinationFour: '',
    whyYouSuitable: '',
  },
  repBeachForm: {
    currentJobTitle: '',
    currentJobFamily: '',
    guestType: [],
    internationalConcept: [],
    nationalConcept: [],
    accelerateDevelopmentProgram: '',
  },
  repCrystalSkiForm: {
    summer2025Placement: '',
    interestedInPlacement: '',
    roleInterested: '',
    availableFrom: '',
    availableTo: '',
    interestReason: '',
    teamManager: '',
  },
  repSkiSkiForm: {
    expressingInterest: '', // "Yes" or "No"
    role: '', // e.g., "Childcare Rep", "Team Leader", etc.
    firstDestination: '', // e.g., "Andorra", "Spain", etc.
    secondDestination: '', // e.g., "Austria", "Canada", etc.
    comfortableDriving: '', // "Yes", "No" or "Non-Driver"
    availableFrom: '', // Format "YYYY-MM"
    availableTo: '', // Format "YYYY-MM"
    dualRole: '', // "Yes" or "No"
    soleRole: '', // "Yes" or "No"
    relationshipDeclaration: '', // Name as per passport, max 50 characters
  },
};
