import React from 'react';
import { Container, Row } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Container>
      <Row className="justify-content-center text-center py-5">
        <p>
          <i className="fa fa-times" /> 404
          <small>Page not found.</small>
        </p>
      </Row>
    </Container>
  );
};

export default NotFound;
