export const ValidateManagerWorkApplicationForm = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError,
  isLongHaul,
  isCrystalSki,
  isSkiSki
) => {
  // console.log('validation', managerWorkApplication);
  // console.log('validation error', managerWorkApplicationError);
  // // console.log(isCrystalSki);
  // console.log(isSkiSki);

  if (isSkiSki) {
    return ManagerSkiSkiFormValidation(
      managerWorkApplication,
      managerWorkApplicationError,
      setManagerWorkApplicationError
    );
  } else if (isCrystalSki) {
    return ManagerCrystalSkiFormValidation(
      managerWorkApplication,
      managerWorkApplicationError,
      setManagerWorkApplicationError
    );
  } else if (isLongHaul) {
    return ManagerLongHaulFormValidation(
      managerWorkApplication,
      managerWorkApplicationError,
      setManagerWorkApplicationError
    );
  } else {
    return ManagerFormValidation(managerWorkApplication, managerWorkApplicationError, setManagerWorkApplicationError);
  }
};

const ManagerFormValidation = (managerWorkApplication, managerWorkApplicationError, setManagerWorkApplicationError) => {
  if (!managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerValidatedWinterAvailability: 'Please select option',
      },
    });
    return false;
  }

  if (
    !managerWorkApplication.managerCommentsForm.managerValidatedWinterAvailabilityComments &&
    managerWorkApplication.managerCommentsForm.isManagerValidatedWinterAvailability === 'No'
  ) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        managerValidatedWinterAvailabilityComments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerValidatedSummerAvailability: 'Please select option',
      },
    });
    return false;
  }

  if (
    !managerWorkApplication.managerCommentsForm.managerValidatedSummerAvailabilityComments &&
    managerWorkApplication.managerCommentsForm.isManagerValidatedSummerAvailability === 'No'
  ) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCommentsForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        managerValidatedSummerAvailabilityComments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please select write comments',
      },
    });
    return false;
  }

  return true;
};

const ManagerLongHaulFormValidation = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError
) => {
  if (!managerWorkApplication.managerLongHaulForm.isManagerSupportApplication) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerLongHaulForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        isManagerSupportApplication: 'Please select option',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerLongHaulForm.comments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerLongHaulForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        comments: 'Please select write comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please select write comments',
      },
    });
    return false;
  }

  return true;
};

const ManagerCrystalSkiFormValidation = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError
) => {
  if (!managerWorkApplication.managerCrystalSkiForm?.suitableForBeachProgramme) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCrystalSkiForm: {
        ...managerWorkApplicationError.managerCommentsForm,
        suitableForBeachProgramme: 'Please select if suitable for beach programme',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerCrystalSkiForm?.comments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerCrystalSkiForm: {
        ...managerWorkApplicationError.comments,
        comments: 'Please add comments',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  return true;
};

const ManagerSkiSkiFormValidation = (
  managerWorkApplication,
  managerWorkApplicationError,
  setManagerWorkApplicationError
) => {
  if (!managerWorkApplication.managerSkiSkiForm?.supportPreferences) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerSkiSkiForm: {
        ...managerWorkApplicationError.managerSkiSkiForm,
        supportPreferences: 'Please select support preferences',
      },
    });
    return false;
  }
  if (managerWorkApplication.managerSkiSkiForm.supportPreferences === 'Yes') {
    if (!managerWorkApplication.managerSkiSkiForm?.inAccelerate) {
      setManagerWorkApplicationError({
        ...managerWorkApplicationError,
        managerSkiSkiForm: {
          ...managerWorkApplicationError.managerSkiSkiForm,
          inAccelerate: 'Please select if is Accelerate',
        },
      });
      return false;
    }

    if (!managerWorkApplication.managerSkiSkiForm?.disciplinaryOrPerformanceManagement) {
      setManagerWorkApplicationError({
        ...managerWorkApplicationError,
        managerSkiSkiForm: {
          ...managerWorkApplicationError.managerSkiSkiForm,
          disciplinaryOrPerformanceManagement: 'Please select if any disciplinary process',
        },
      });
      return false;
    }

    if (!managerWorkApplication.managerSkiSkiForm?.greatPlaceToGrowRating) {
      setManagerWorkApplicationError({
        ...managerWorkApplicationError,
        managerSkiSkiForm: {
          ...managerWorkApplicationError.managerSkiSkiForm,
          greatPlaceToGrowRating: 'Please add grow rating',
        },
      });
      return false;
    }
  }

  if (!managerWorkApplication.managerWorkApplicationForm.location) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        location: 'Please select location',
      },
    });
    return false;
  }

  if (!managerWorkApplication.managerWorkApplicationForm.managerComments) {
    setManagerWorkApplicationError({
      ...managerWorkApplicationError,
      managerWorkApplicationForm: {
        ...managerWorkApplicationError.managerWorkApplicationForm,
        managerComments: 'Please add somethign',
      },
    });
    return false;
  }

  return true;
};
