export const RepFormValidation = (
  repWorkApplication,
  repWorkApplicationError,
  setRepWorkApplicationError,
  isLongHaul,
  isCrystalSki,
  isSkiSki
) => {
  // outputs
  // console.log(repWorkApplication);
  // console.log(repWorkApplicationError);
  // console.log(isLongHaul);
  // console.log(isSkiSki);
  if (isSkiSki) {
    const isValidSkiSkiForm = validateSkiSki(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidSkiSkiForm) return isValidSkiSkiForm;
  } else if (isCrystalSki) {
    const isValidWinterForm = validateCrystalSki(
      repWorkApplication,
      repWorkApplicationError,
      setRepWorkApplicationError
    );
    if (!isValidWinterForm) return isValidWinterForm;
  } else if (isLongHaul) {
    const isValidWinterForm = validateLongHaul(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidWinterForm) return isValidWinterForm;
  } else {
    const isValidBeachForm = validateBeach(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidBeachForm) return isValidBeachForm;

    const isValidWinterForm = validateWinter(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidWinterForm) return isValidWinterForm;

    const isValidSummerForm = validateSummer(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
    if (!isValidSummerForm) return isValidSummerForm;
  }

  const isValidGlobalForm = validateGlobal(repWorkApplication, repWorkApplicationError, setRepWorkApplicationError);
  if (!isValidGlobalForm) return isValidGlobalForm;

  return true;
};

const validateGlobal = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repGlobalForm.location) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repGlobalForm: {
        ...repWorkApplicationError.repGlobalForm,
        location: 'Please select a location',
      },
    });
    return false;
  }

  if (!repWorkApplication.repGlobalForm.managerName) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repGlobalForm: {
        ...repWorkApplicationError.repGlobalForm,
        managerName: 'Please enter the manager name',
      },
    });
    return false;
  }

  return true;
};

const validateBeach = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repBeachForm.accelerateDevelopmentProgram) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repBeachForm: { accelerateDevelopmentProgram: 'Please select if you are enrolled in the accelerate program' },
    });
    return false;
  }

  if (!repWorkApplication.repBeachForm.currentJobFamily) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repBeachForm: { currentJobFamily: 'Please select the current job family' },
    });
    return false;
  }

  if (!repWorkApplication.repBeachForm.currentJobTitle) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repBeachForm: { currentJobTitle: 'Please select the current job title' },
    });
    return false;
  }

  return true;
};
const validateWinter = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repWinterForm.preferencesFor) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { preferencesFor: 'Please select your preferences for winter' },
    });
    return false;
  }

  if (
    repWorkApplication.repWinterForm.preferencesFor ===
      'I do not wish to work in winter but would like to return for summer' ||
    repWorkApplication.repWinterForm.preferencesFor ===
      'I do not wish to work in winter and plan to resign at the end of summer'
  ) {
    return true;
  }

  if (
    repWorkApplication.repWinterForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repWinterForm.preferenceStartMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { preferenceStartMonth: 'Please select the preference start month' },
    });
    return false;
  }

  if (
    repWorkApplication.repWinterForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repWinterForm.preferenceEndMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { preferenceEndMonth: 'Please select the preference end month' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.firstChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { firstChoiceDestination: 'Please select the first choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.secondChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { secondChoiceDestination: 'Please select the second choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.customerNationalities) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { customerNationalities: 'Please select the customer nationalities' },
    });
    return false;
  }

  if (!repWorkApplication.repWinterForm.willingCompleteVisaApplication) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: {
        willingCompleteVisaApplication: 'Please select if you are willing to complete the visa application',
      },
    });

    return false;
  }

  if (!repWorkApplication.repWinterForm.considerChangeRole) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: { considerChangeRole: 'Please select if you would consider a change of role' },
    });

    return false;
  }

  if (
    repWorkApplication.repWinterForm.considerChangeRole === 'Yes' &&
    !repWorkApplication.repWinterForm.considerChangeRoleJobTitles
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repWinterForm: {
        considerChangeRoleJobTitles: 'Please select the job titles you would consider for a change of role',
      },
    });
    return false;
  }

  return true;
};
const validateSummer = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (
    repWorkApplication.repWinterForm.preferencesFor ===
    'I do not wish to work in winter and plan to resign at the end of summer'
  ) {
    return true;
  }

  if (!repWorkApplication.repSummerForm.preferencesFor) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { preferencesFor: 'Please select your preferences for summer' },
    });
    return false;
  }

  if (
    repWorkApplication.repSummerForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repSummerForm.preferenceStartMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { preferenceStartMonth: 'Please select the preference start month' },
    });
    return false;
  }

  if (
    repWorkApplication.repSummerForm.preferencesFor === 'Would like to work part winter only' &&
    !repWorkApplication.repSummerForm.preferenceEndMonth
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { preferenceEndMonth: 'Please select the preference end month' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.firstChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { firstChoiceDestination: 'Please select the first choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.secondChoiceDestination) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { secondChoiceDestination: 'Please select the second choice destination' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.customerNationalities) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { customerNationalities: 'Please select the customer nationalities' },
    });
    return false;
  }

  if (!repWorkApplication.repSummerForm.willingCompleteVisaApplication) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: {
        willingCompleteVisaApplication: 'Please select if you are willing to complete the visa application',
      },
    });

    return false;
  }

  if (!repWorkApplication.repSummerForm.considerChangeRole) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: { considerChangeRole: 'Please select if you would consider a change of role' },
    });

    return false;
  }

  if (
    repWorkApplication.repSummerForm.considerChangeRole === 'Yes' &&
    !repWorkApplication.repSummerForm.considerChangeRoleJobTitles
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSummerForm: {
        considerChangeRoleJobTitles: 'Please select the job titles you would consider for a change of role',
      },
    });
    return false;
  }

  return true;
};

const validateLongHaul = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (
    !Array.isArray(repWorkApplication.repLongHaulForm.confortableSourceMarket) ||
    repWorkApplication.repLongHaulForm.confortableSourceMarket === null ||
    repWorkApplication.repLongHaulForm.confortableSourceMarket.length === 0
  ) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { confortableSourceMarket: ['Please select a comfortable source market'] },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.passportExpiry) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { passportExpiry: 'Please select the passport expiry date' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.currentJobFamily) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { currentJobFamily: 'Please select the current job family' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.currentJobTitle) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { currentJobTitle: 'Please select the current job title' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationOne) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationOne: 'Please select the first destination' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationTwo) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationTwo: 'Please select the second destination' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationThree) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationThree: 'Please select the third destination' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.destinationFour) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { destinationFour: 'Please select the fourth destination' },
    });
    return false;
  }

  if (!repWorkApplication.repLongHaulForm.whyYouSuitable) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repLongHaulForm: { whyYouSuitable: 'Please explain why you are suitable for this role' },
    });
    return false;
  }

  return true;
};

const validateCrystalSki = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repCrystalSkiForm.summer2025Placement) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repCrystalSkiForm: { summer2025Placement: 'Please select if you have a Summer 2025 Placement' },
    });
    return false;
  }

  if (repWorkApplication.repCrystalSkiForm.summer2025Placement === 'No') {
    if (!repWorkApplication.repCrystalSkiForm.interestedInPlacement) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repCrystalSkiForm: { interestedInPlacement: 'Please select if you are interested in a Summer 2025 Placement' },
      });
      return false;
    }
    if (repWorkApplication.repCrystalSkiForm.interestedInPlacement === 'Yes') {
      if (!repWorkApplication.repCrystalSkiForm.roleInterested) {
        setRepWorkApplicationError({
          ...repWorkApplicationError,
          repCrystalSkiForm: { roleInterested: 'Please select the role you are interested in' },
        });
        return false;
      }

      if (!repWorkApplication.repCrystalSkiForm.availableFrom) {
        setRepWorkApplicationError({
          ...repWorkApplicationError,
          repCrystalSkiForm: { availableFrom: 'Please select the start date of your availability' },
        });
        return false;
      }

      if (!repWorkApplication.repCrystalSkiForm.availableTo) {
        setRepWorkApplicationError({
          ...repWorkApplicationError,
          repCrystalSkiForm: { availableTo: 'Please select the end date of your availability' },
        });
        return false;
      }

      if (!repWorkApplication.repCrystalSkiForm.interestReason) {
        setRepWorkApplicationError({
          ...repWorkApplicationError,
          repCrystalSkiForm: { interestReason: 'Please explain your reason for interest' },
        });
        return false;
      }
    }
  }

  return true;
};

const validateSkiSki = (repWorkApplication, repWorkApplicationError, setRepWorkApplicationError) => {
  if (!repWorkApplication.repSkiSkiForm.expressingInterest) {
    setRepWorkApplicationError({
      ...repWorkApplicationError,
      repSkiSkiForm: { expressingInterest: 'Please select if you are expressing interest' },
    });
    return false;
  }

  if (repWorkApplication.repSkiSkiForm.expressingInterest === 'Yes') {
    if (!repWorkApplication.repSkiSkiForm.role) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { role: 'Please select a role' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.firstDestination) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { firstDestination: 'Please select the first destination' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.secondDestination) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { secondDestination: 'Please select the second destination' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.comfortableDriving) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { comfortableDriving: 'Please select if you are comfortable driving' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.availableFrom) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { availableFrom: 'Please select the start date of your availability' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.availableTo) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { availableTo: 'Please select the end date of your availability' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.dualRole) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { dualRole: 'Please select if you are interested in a dual role' },
      });
      return false;
    }

    if (!repWorkApplication.repSkiSkiForm.soleRole) {
      setRepWorkApplicationError({
        ...repWorkApplicationError,
        repSkiSkiForm: { soleRole: 'Please select if you are interested in a sole role' },
      });
      return false;
    }
  }

  return true;
};
