import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import RestClient from 'Services/RestClientService';
import JobAssignmentCard from 'Components/Cards/JobAssignmentCard';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';

const JobAssignmentView = () => {
  const dispatch = useDispatch();
  const currentUserEmail = useSelector((state) => state.currentUser.email);
  const jobFamiliesWork = useSelector((state) => state.settings.jobFamiliesWork);
  const destinationsWork = useSelector((state) => state.settings.acceptDeclineCountry);
  const seasonsOpen = useSelector((state) => state.settings.seasonsOpen);

  const [assignmentsList, setAssignmentsList] = useState([]);
  const [employee, setEmployee] = useState({});
  const [refreshParentComponent, setParentRefreshComponent] = useState(0);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      dispatch(setLoader(true));
      try {
        if (!currentUserEmail) return;
        const staffInfo = await RestClient.Get(`staff/getbyemail/${currentUserEmail}`);
        if (staffInfo) setEmployee(staffInfo);
      } catch (error) {
        toastr.error('Error fetching employee data');
      } finally {
        dispatch(setLoader(false));
      }
    };

    fetchEmployeeData();
  }, [currentUserEmail, dispatch]);

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        if (!employee || !employee.staffID) return;
        const response = await RestClient.Get(`positionAssign/byStaffId/${employee.staffID}`);
        if (!response || !response.positionAssignList) return;

        let filteredAssignments = filterAssignments(response.positionAssignList);
        setAssignmentsList(filteredAssignments);
      } catch (error) {
        toastr.error('Error fetching assignments');
      }
    };

    fetchAssignments();
  }, [employee, refreshParentComponent]);

  const filterAssignments = (assignments) => {
    let filteredAssignments = assignments;

    if (jobFamiliesWork) {
      filteredAssignments = filteredAssignments.filter((assign) =>
        jobFamiliesWork.split(',').some((element) => element.toLowerCase() === assign.jobFamily.toLowerCase())
      );
    }

    if (destinationsWork) {
      filteredAssignments = filteredAssignments.filter((assign) =>
        destinationsWork.split(',').some((element) => element.toLowerCase() === assign.destination.toLowerCase())
      );
    }

    if (seasonsOpen) {
      filteredAssignments = filteredAssignments.filter((assign) =>
        seasonsOpen.split(',').some((element) => element.toLowerCase() === assign.season.toLowerCase())
      );
    }

    filteredAssignments = filteredAssignments.filter(
      (assign) => assign.status?.toLowerCase() !== 'pending' && assign.accept?.toLowerCase() !== 'pending'
    );

    return filteredAssignments;
  };

  return (
    <Container fluid>
      <h1 className="my-2">Job Assignments</h1>
      {assignmentsList && assignmentsList.length > 0 ? (
        assignmentsList.map((assignment, index) => (
          <JobAssignmentCard
            key={index}
            assignment={assignment}
            employee={employee}
            setRefreshParentComponent={setParentRefreshComponent}
            refreshParentComponent={refreshParentComponent}
          />
        ))
      ) : (
        <>
          <hr style={{ marginTop: '1%' }} />
          <Row className="justify-content-center text-center mt-3">
            <Col sm={12} md={8}>
              <h4>
                <i className="fa fa-search" /> Can’t find any assignment for you
              </h4>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center', textAlign: 'center', marginTop: '1%' }}>
            <small>Seems like you are not assigned to any position yet!</small>
          </Row>
        </>
      )}
    </Container>
  );
};

export default JobAssignmentView;
