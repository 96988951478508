import React, { useEffect, useState } from 'react';
import { Card, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

import {
  BeachGuestType,
  BeachInternationalConcepts,
  BeachNationalConcepts,
  BeachJobFamilies,
  JobFamilyToJobTitlesMapping,
} from 'Handlers/BeachDropDownsHandler';

const RepBeachForm = (props) => {
  const {
    repWorkApplication,
    setRepWorkApplication,

    repWorkApplicationError,
    setRepWorkApplicationError,

    isWorkApplicationSubmitted,
  } = props;

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  const cardStyle = {
    marginTop: '1%',
  };

  const [BeachJobTitles, setBeachJobTitles] = useState([]);

  useEffect(() => {
    if (repWorkApplication.repBeachForm?.currentJobFamily) {
      setBeachJobTitles(JobFamilyToJobTitlesMapping(repWorkApplication.repBeachForm.currentJobFamily));
    }
  }, [repWorkApplication.repBeachForm?.currentJobFamily]);

  return (
    <>
      <h2 style={cardStyle}>General Information</h2>

      <Card style={cardStyle}>
        <Card.Header>
          <i className="fa fa-user mr-2" />
          Initial preferences - Related to the full application
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Row>
              <Form.Group as={Col} lg={12}>
                <Form.Label>I am enrolled in the Accelerate Development Programme {requiredField()}</Form.Label>
                <Col>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    value="Yes"
                    checked={repWorkApplication.repBeachForm.accelerateDevelopmentProgram === 'Yes'}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          accelerateDevelopmentProgram: e.currentTarget.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repBeachForm: {
                          ...repWorkApplicationError.repBeachForm,
                          accelerateDevelopmentProgram: null,
                        },
                      });
                    }}
                    disabled={isWorkApplicationSubmitted}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    value="No"
                    checked={repWorkApplication.repBeachForm.accelerateDevelopmentProgram === 'No'}
                    onChange={(e) => {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          accelerateDevelopmentProgram: e.currentTarget.value,
                        },
                      });
                      setRepWorkApplicationError({
                        ...repWorkApplicationError,
                        repBeachForm: {
                          ...repWorkApplicationError.repBeachForm,
                          accelerateDevelopmentProgram: null,
                        },
                      });
                    }}
                    disabled={isWorkApplicationSubmitted}
                  />
                </Col>
                {repWorkApplicationError.repBeachForm?.accelerateDevelopmentProgram && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repBeachForm?.accelerateDevelopmentProgram}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Current Job Family {requiredField()}</Form.Label>
                <Select
                  options={BeachJobFamilies}
                  value={
                    BeachJobFamilies.find(
                      (option) => option.value === repWorkApplication.repBeachForm?.currentJobFamily
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          currentJobFamily: null,
                        },
                      });
                      setBeachJobTitles([]);
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repBeachForm: {
                        ...repWorkApplication.repBeachForm,
                        currentJobFamily: e.value,
                      },
                    });
                    setBeachJobTitles(JobFamilyToJobTitlesMapping(e.value));
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repBeachForm: {
                        ...repWorkApplicationError.repBeachForm,
                        currentJobFamily: null,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repBeachForm?.currentJobFamily && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repBeachForm?.currentJobFamily}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} lg={6}>
                <Form.Label>Current Job Title {requiredField()}</Form.Label>
                <Select
                  options={BeachJobTitles}
                  value={
                    BeachJobTitles.find(
                      (option) => option.value === repWorkApplication.repBeachForm?.currentJobTitle
                    ) || null
                  }
                  onChange={(e) => {
                    if (!e || !e.value) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          currentJobTitle: null,
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repBeachForm: {
                        ...repWorkApplication.repBeachForm,
                        currentJobTitle: e.value,
                      },
                    });
                    setRepWorkApplicationError({
                      ...repWorkApplicationError,
                      repBeachForm: {
                        ...repWorkApplicationError.repBeachForm,
                        currentJobTitle: null,
                      },
                    });
                  }}
                  isClearable
                  isDisabled={isWorkApplicationSubmitted}
                />
                {repWorkApplicationError.repBeachForm?.currentJobTitle && (
                  <Form.Text className="form-text-red">
                    {repWorkApplicationError.repBeachForm?.currentJobTitle}
                  </Form.Text>
                )}
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>
                  What guest type are you happy to work with?{' '}
                  <small style={{ color: 'grey', fontSize: '10px' }}>(multiple selection)</small>
                </Form.Label>
                <Select
                  options={BeachGuestType}
                  value={
                    repWorkApplication.repBeachForm?.guestType?.map((value) =>
                      BeachGuestType.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          guestType: [],
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repBeachForm: {
                        ...repWorkApplication.repBeachForm,
                        guestType: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>
                  International Concepts{' '}
                  <small style={{ color: 'grey', fontSize: '10px' }}>(multiple selection)</small>{' '}
                </Form.Label>
                <Select
                  options={BeachInternationalConcepts}
                  value={
                    repWorkApplication.repBeachForm?.internationalConcept?.map((value) =>
                      BeachInternationalConcepts.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          internationalConcept: [],
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repBeachForm: {
                        ...repWorkApplication.repBeachForm,
                        internationalConcept: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>
                  National Concepts <small style={{ color: 'grey', fontSize: '10px' }}>(multiple selection)</small>{' '}
                </Form.Label>
                <Select
                  options={BeachNationalConcepts}
                  value={
                    repWorkApplication.repBeachForm?.nationalConcept?.map((value) =>
                      BeachNationalConcepts.find((option) => option.value === value)
                    ) || []
                  }
                  onChange={(e) => {
                    if (!e || !e.length) {
                      setRepWorkApplication({
                        ...repWorkApplication,
                        repBeachForm: {
                          ...repWorkApplication.repBeachForm,
                          nationalConcept: [],
                        },
                      });
                      return;
                    }
                    setRepWorkApplication({
                      ...repWorkApplication,
                      repBeachForm: {
                        ...repWorkApplication.repBeachForm,
                        nationalConcept: e.map((option) => option.value),
                      },
                    });
                  }}
                  isClearable
                  isMulti
                  isDisabled={isWorkApplicationSubmitted}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default RepBeachForm;
