export const initialManagerWorkApplicationState = {
  managerWorkApplicationForm: {
    signature: '',
    location: '',
    managerComments: '',
  },
  managerCommentsForm: {
    isManagerValidatedWinterAvailability: '',
    managerValidatedWinterAvailabilityComments: '',
    isManagerValidatedSummerAvailability: '',
    managerValidatedSummerAvailabilityComments: '',
    managerSupportsAdp: '',
    isPerformanceManagementInprocess: '',
    isManagerValidatesSourceMarkets: '',
    managerValidatesSourceMarketsComments: '',
    collegueWorkingConcept: [],
    managerColleagueStrenghts: [],
    managerDevelopmentAreas: [],
  },
  managerLongHaulForm: {
    isManagerValidatedAvailability: '',
    comments: '',
  },
  managerCrystalSkiForm: {
    suitableForBeachProgramme: '',
    comments: '',
  },
};

export const initialManagerWorkApplicationErrorState = {
  managerWorkApplicationForm: {
    signature: '',
    location: '',
    managerComments: '',
  },
  managerLongHaulForm: {
    isManagerValidatedWinterAvailability: '',
    comments: '',
  },
  managerCommentsForm: {
    isManagerValidatedWinterAvailability: '',
    managerValidatedWinterAvailabilityComments: '',
    isManagerValidatedSummerAvailability: '',
    managerValidatedSummerAvailabilityComments: '',
    managerSupportsAdp: '',
    isPerformanceManagementInprocess: '',
    isManagerValidatesSourceMarkets: '',
    managerValidatesSourceMarketsComments: '',
    collegueWorkingConcept: [],
    managerColleagueStrenghts: [],
    managerDevelopmentAreas: [],
  },
  managerCrystalSkiForm: {
    suitableForBeachProgramme: '',
    comments: '',
  },
};
