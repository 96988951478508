import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from 'Redux/Actions/User/UserAction';
import { authContext } from 'Configuration/AdalConfiguration';

const Avatar = () => {
  const [showInfo, setShowInfo] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const infoRef = useRef(null);

  const handleAvatarClick = () => {
    setShowInfo(!showInfo);
  };

  const handleClickOutside = (event) => {
    if (infoRef.current && !infoRef.current.contains(event.target)) {
      setShowInfo(false);
    }
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (showInfo) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showInfo]);

  const formatRoles = (roles) => {
    if (roles.length === 0) {
      return '';
    }
    let result = roles.filter((value) => value.includes('DS_MTP'));
    return result;
  };

  return (
    <div className="navbar-nav ml-auto position-relative avatar-container">
      <div className="avatar-email">{currentUser.email}</div>
      <Button variant="link" className="p-0 avatar-button" onClick={handleAvatarClick}>
        <div
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            backgroundColor: '#007bff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'white',
            fontSize: '1rem',
          }}
        >
          {currentUser.given_name.charAt(0) + currentUser.family_name.charAt(0)}
        </div>
      </Button>
      {showInfo && (
        <div className="user-info" ref={infoRef}>
          <Row>
            <Col xs={12}>{currentUser.family_name + ', ' + currentUser.given_name}</Col>
            <Col xs={12}>{currentUser.email}</Col>
            <Col xs={12}>{formatRoles(currentUser.roles)}</Col>
            <Col xs={12} className="text-right" style={{ marginTop: '5%' }}>
              <Button
                size="sm"
                onClick={() => {
                  authContext.logOut();
                }}
              >
                Logout
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default Avatar;
