import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button, Alert, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import Datetime from 'react-datetime';
import { toastr } from 'react-redux-toastr';

import ManagerCommentsForm from 'Components/WorkApplicationForms/ManagerCommentsForm';
import { setLoader } from 'Redux/Actions/Loading/LoadingAction';
import RestClient from 'Services/RestClientService';
import { DATE_FORMAT_WEB, DATETIME_FORMAT_ISO } from 'Constants/dateFormatConstants';
import { DateTimeFormater } from 'Handlers/DateTimeHandler';
import { ValidateManagerWorkApplicationForm } from 'Components/WorkApplicationForms/FormValidation/ManagerFormValidation';
import RepWinterForm from '../../Components/WorkApplicationForms/RepWinterForm';
import RepSummerForm from '../../Components/WorkApplicationForms/RepSummerForm';
import ManagerLongHaulCommentsForm from '../../Components/WorkApplicationForms/ManagerLongHaulCommentsForm';
import ManagerCrystalSkyCommentsForm from '../../Components/WorkApplicationForms/ManagerCrystalSkyCommentsForm';
import ManagerSkiSkiCommentsForm from '../../Components/WorkApplicationForms/ManagerSkiSkiCommentsForm';
import RepLongHaulForm from 'Components/WorkApplicationForms/RepLongHaulForm';
import RepBeachForm from 'Components/WorkApplicationForms/RepBeachForm';
import RepCrystalSkiForm from 'Components/WorkApplicationForms/RepCrystalSkiForm';
import RepSkiSkiForm from 'Components/WorkApplicationForms/RepSkiSkiForm';
import { initialRepWorkApplicationState } from './initialStates';
import { initialManagerWorkApplicationState, initialManagerWorkApplicationErrorState } from './initialStatesManager';

const ManagerWorkApplicationForm = (props) => {
  const dispatch = useDispatch();
  const { staffId, refreshParentComponent, setRefreshParentComponent } = props;

  const { curSeason, nextSeason } = useSelector((state) => state.settings);
  const currentUserEmail = useSelector((state) => state.currentUser.email);
  const currentUserStaffId = useSelector((state) => state.currentUser.staffId);

  const countriesOptionsList = useSelector((state) => state.masterData.countries);
  const jobFamiliesOptionsList = useSelector((state) => state.masterData.jobFamilies);
  const jobTitlesOptionsList = useSelector((state) => state.masterData.jobTitles);

  const tppSettings = useSelector((state) => state.settings);
  const [isCrystalSki, setIsCrystalSki] = useState(false);
  const [isSkiSki, setIsSkiSki] = useState(true);

  const [managerWorkApplication, setManagerWorkApplication] = useState(initialManagerWorkApplicationState);
  const [managerWorkApplicationError, setManagerWorkApplicationError] = useState(
    initialManagerWorkApplicationErrorState
  );

  const [repWorkApplication, setRepWorkApplication] = useState(initialRepWorkApplicationState);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowRepFormModal, setIsShowRepFormModal] = useState(false);
  const [isManagerWorkApplicationSubmitted, setIsManagerWorkApplicationSubmitted] = useState(false);
  const [isThisStaffBelongToThisManager, setIsThisStaffBelongToThisManager] = useState(false);

  const handleSendManagerForm = () => {
    // var validJobFamilies = tppSettings.jobFamiliesWork.split(',').map((jobFamily) => jobFamily.trim().toLowerCase());
    // setIsCrystalSki(validJobFamilies.some((assign) => assign.toLowerCase().includes('crystal ski')));
    if (
      !ValidateManagerWorkApplicationForm(
        managerWorkApplication,
        managerWorkApplicationError,
        setManagerWorkApplicationError,
        tppSettings.isLongHaul,
        isCrystalSki,
        isSkiSki
      )
    ) {
      // toastr.warning('Required fields', 'Please fill all required fields');
      return;
    }

    //add exta fields
    managerWorkApplication.managerWorkApplicationForm.staffIdRep = staffId;
    managerWorkApplication.managerWorkApplicationForm.staffIdManager = currentUserStaffId;

    managerWorkApplication.managerWorkApplicationForm.signature = currentUserEmail;
    managerWorkApplication.managerWorkApplicationForm.currentSeason = curSeason;
    managerWorkApplication.managerWorkApplicationForm.nextSeason = nextSeason;
    managerWorkApplication.isLongHaul = tppSettings.isLongHaul;
    managerWorkApplication.isCrystalSki = isCrystalSki;
    managerWorkApplication.isSkiSki = true;

    dispatch(setLoader(true));
    RestClient.Post(`workApplication/manager/`, managerWorkApplication)
      .then((response) => {
        if (!response) return;
        // console.log(response);
        if (response.status) {
          toastr.success('Success', response.message);
          setRefreshParentComponent(Math.random());
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        dispatch(setLoader(false));
      });
  };

  const isObjectEmpty = (obj) => {
    if (obj === null || obj === undefined) {
      return true;
    }
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    setIsThisStaffBelongToThisManager(false);
    // var validJobFamilies = tppSettings.jobFamiliesWork.split(',').map((jobFamily) => jobFamily.trim().toLowerCase());
    // setIsCrystalSki(validJobFamilies.some((assign) => assign.toLowerCase().includes('crystal ski')));
    console.log('staffId', staffId);
    if (!curSeason || !staffId) return;
    // get manager form for current select staff
    RestClient.Get(`workapplication/manager/${staffId}/${curSeason}/${isCrystalSki}/${isSkiSki}`)
      .then((response) => {
        console.log('workapplication/manager/', response);
        if (!response) return;
        if (response.status && !response.managerWorkApplication) {
          // toastr.info('Info', 'No manager work application found');
          setManagerWorkApplication({
            managerWorkApplicationForm: {},
            managerCommentsForm: {},
            managerLongHaulForm: {},
            managerCrystalSkiForm: {},
            managerSkiSkiForm: {},
          });
          setIsManagerWorkApplicationSubmitted(false);
        } else if (
          response.status &&
          response.managerWorkApplication &&
          (response.managerCommentsForm ||
            response.managerLongHaulCommentsForm ||
            response.managerCrystalSkiCommentsForm ||
            response.managerSkiSkiCommentsForm)
        ) {
          // toastr.success('Success', response.message);
          setManagerWorkApplication({
            managerWorkApplicationForm: response.managerWorkApplication,
            managerCommentsForm: response.managerCommentsForm,
            managerLongHaulForm: response.managerLongHaulCommentsForm,
            managerCrystalSkiForm: response.managerCrystalSkiCommentsForm,
            managerSkiSkiForm: response.managerSkiSkiCommentsForm,
          });
          setIsManagerWorkApplicationSubmitted(true);
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // get rep form for current select staff
    RestClient.Get(`workapplication/rep/${staffId}/${curSeason}/${isCrystalSki}/${isSkiSki}`)
      .then((response) => {
        console.log('workapplication/rep/', response);
        if (!response) return;
        if (response.status && !response.repWorkApplication) {
          // toastr.info('Info', 'No rep work application found');
          setRepWorkApplication({
            repGlobalForm: null,
            repBeachForm: null,
            repSummerForm: null,
            repWinterForm: null,
            repLongHaulForm: null,
            repCrystalSkiForm: null,
            repSkiSkiForm: null,
          });
        } else if (
          response.status &&
          response.repWorkApplication &&
          ((response.repSummerForm && response.repWinterForm) ||
            response.repLongHaulForm ||
            response.repCrystalSkiForm ||
            response.repSkiSkiForm)
        ) {
          // toastr.success('Success', response.message);
          setRepWorkApplication({
            repGlobalForm: response.repWorkApplication,
            repBeachForm: response.repBeachForm,
            repSummerForm: response.repSummerForm,
            repWinterForm: response.repWinterForm,
            repLongHaulForm: response.repLongHaulForm,
            repCrystalSkiForm: response.repCrystalSkiForm,
            repSkiSkiForm: response.repSkiSkiForm,
          });
          if (response.repWorkApplication.managerName == currentUserStaffId) {
            setIsThisStaffBelongToThisManager(true);
          }
        } else {
          toastr.error('Error', response.message);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [staffId, curSeason, refreshParentComponent]);

  const cardStyle = {
    marginTop: '0.5%',
  };

  const requiredField = () => {
    return <small style={{ color: 'grey', fontSize: '10px' }}>(required)</small>;
  };

  return (
    <React.Fragment>
      {!isLoading && !isThisStaffBelongToThisManager && (
        <Alert
          variant="warning"
          style={{
            marginTop: '1%',
          }}
        >
          <p>
            Something doesn´t fit us in this profile. Apparently this staff do not belongs to you as a manager or the
            staff has not filled out the form so that manager comments can be sent. Please check that your employees
            have submitted the form so you can see it here.
          </p>
        </Alert>
      )}

      {!isLoading && repWorkApplication && repWorkApplication.repGlobalForm && isThisStaffBelongToThisManager && (
        <Alert
          variant="success"
          style={{
            marginTop: '1%',
          }}
        >
          <p>
            The rep submited on{' '}
            <strong>{DateTimeFormater(repWorkApplication.repGlobalForm.timestamp, null, DATE_FORMAT_WEB)} </strong>
            from <strong>{repWorkApplication.repGlobalForm?.location}</strong> .
            <Button type="button" onClick={() => setIsShowRepFormModal(true)} class="btn btn-link">
              <small>+info</small>
            </Button>
          </p>
        </Alert>
      )}

      {!isLoading &&
        managerWorkApplication &&
        isThisStaffBelongToThisManager &&
        !isObjectEmpty(managerWorkApplication.managerWorkApplicationForm) && (
          <Alert
            variant="success"
            style={{
              marginTop: '1%',
            }}
          >
            <p>
              The manager <strong>{managerWorkApplication.managerWorkApplicationForm.staffIdManager}</strong> ,submited{' '}
              the rep <strong>{managerWorkApplication.managerWorkApplicationForm.staffIdRep}</strong> form on{' '}
              <strong>
                {DateTimeFormater(
                  managerWorkApplication.managerWorkApplicationForm.submitDate,
                  DATETIME_FORMAT_ISO,
                  DATE_FORMAT_WEB
                )}
              </strong>{' '}
              from <strong>{managerWorkApplication.managerWorkApplicationForm?.location}</strong> .
            </p>
          </Alert>
        )}

      {!isLoading && isThisStaffBelongToThisManager && (
        <>
          {isSkiSki ? (
            <>
              <ManagerSkiSkiCommentsForm
                managerWorkApplication={managerWorkApplication}
                setManagerWorkApplication={setManagerWorkApplication}
                managerWorkApplicationError={managerWorkApplicationError}
                setManagerWorkApplicationError={setManagerWorkApplicationError}
                isManagerWorkApplicationSubmitted={isManagerWorkApplicationSubmitted}
              />
            </>
          ) : isCrystalSki ? (
            <>
              <ManagerCrystalSkyCommentsForm
                managerWorkApplication={managerWorkApplication}
                setManagerWorkApplication={setManagerWorkApplication}
                managerWorkApplicationError={managerWorkApplicationError}
                setManagerWorkApplicationError={setManagerWorkApplicationError}
                isManagerWorkApplicationSubmitted={isManagerWorkApplicationSubmitted}
              />
            </>
          ) : tppSettings.isLongHaul ? (
            <>
              <ManagerLongHaulCommentsForm
                managerWorkApplication={managerWorkApplication}
                setManagerWorkApplication={setManagerWorkApplication}
                isManagerWorkApplicationSubmitted={isManagerWorkApplicationSubmitted}
              />
            </>
          ) : (
            <>
              <ManagerCommentsForm
                managerWorkApplication={managerWorkApplication}
                setManagerWorkApplication={setManagerWorkApplication}
                managerWorkApplicationError={managerWorkApplicationError}
                setManagerWorkApplicationError={setManagerWorkApplicationError}
                isManagerWorkApplicationSubmitted={isManagerWorkApplicationSubmitted}
              />
            </>
          )}

          <Card style={cardStyle}>
            <Card.Header>
              <i className="fa fa-check mr-2" />
              Submit form
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formSignature">
                      <Form.Label>Signature</Form.Label>
                      <Form.Control type="text" disabled value={currentUserEmail || ''} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="formLocation">
                      <Form.Label>Location {requiredField()}</Form.Label>
                      <Select
                        options={countriesOptionsList}
                        onChange={(e) => {
                          setManagerWorkApplication({
                            ...managerWorkApplication,
                            managerWorkApplicationForm: {
                              ...managerWorkApplication.managerWorkApplicationForm,
                              location: e?.value,
                            },
                            managerCommentsForm: {
                              ...managerWorkApplication.managerCommentsForm,
                            },
                          });
                          setManagerWorkApplicationError({
                            managerWorkApplicationForm: {
                              location: null,
                            },
                            managerCommentsForm: {},
                          });
                        }}
                        value={
                          countriesOptionsList.find(
                            (obj) => obj.value === managerWorkApplication.managerWorkApplicationForm?.location
                          ) || null
                        }
                        isClearable
                        isSearchable
                        isDisabled={isManagerWorkApplicationSubmitted}
                      />
                      {managerWorkApplicationError.managerWorkApplicationForm?.location && (
                        <Form.Text className="form-text-red">
                          {managerWorkApplicationError.managerWorkApplicationForm?.location}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Form.Group controlId="formComments">
                      <Form.Label>Any other comments? {requiredField()}</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={8}
                        value={managerWorkApplication.managerWorkApplicationForm?.managerComments}
                        onChange={(e) => {
                          setManagerWorkApplication({
                            ...managerWorkApplication,
                            managerWorkApplicationForm: {
                              ...managerWorkApplication.managerWorkApplicationForm,
                              managerComments: e.target.value,
                            },
                            managerCommentsForm: {
                              ...managerWorkApplication.managerCommentsForm,
                            },
                          });
                          setManagerWorkApplicationError({
                            managerWorkApplicationForm: {
                              managerComments: null,
                            },
                            managerCommentsForm: {},
                          });
                        }}
                        disabled={isManagerWorkApplicationSubmitted}
                      />
                      {managerWorkApplicationError.managerWorkApplicationForm?.managerComments && (
                        <Form.Text className="form-text-red">
                          {managerWorkApplicationError.managerWorkApplicationForm?.managerComments}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>

          <Row style={{ display: 'flex', flexDirection: 'row-reverse', padding: '1%' }}>
            <Button
              variant="success"
              style={{ marginRight: '10px' }}
              onClick={handleSendManagerForm}
              size="lg"
              disabled={isManagerWorkApplicationSubmitted}
            >
              Submit
            </Button>
          </Row>

          <Modal
            show={isShowRepFormModal}
            onHide={() => {
              setIsShowRepFormModal(false);
            }}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Rep Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isSkiSki ? (
                <>
                  <RepSkiSkiForm
                    repWorkApplication={repWorkApplication}
                    repWorkApplicationError={{
                      repSkiSkiForm: {},
                    }}
                    setRepWorkApplicationError={null}
                    setRepWorkApplication={setRepWorkApplication}
                    isWorkApplicationSubmitted={true}
                  />
                </>
              ) : isCrystalSki ? (
                <>
                  <RepCrystalSkiForm
                    repWorkApplication={repWorkApplication}
                    repWorkApplicationError={{
                      repCrystalSkiForm: {},
                    }}
                    setRepWorkApplicationError={null}
                    setRepWorkApplication={setRepWorkApplication}
                    isWorkApplicationSubmitted={true}
                  />
                </>
              ) : tppSettings.isLongHaul ? (
                <>
                  <RepLongHaulForm
                    repWorkApplication={repWorkApplication}
                    setRepWorkApplication={setRepWorkApplication}
                    isWorkApplicationSubmitted={true}
                  />
                </>
              ) : (
                <>
                  <RepBeachForm
                    jobFamiliesOptionsList={jobFamiliesOptionsList}
                    jobTitlesOptionsList={jobTitlesOptionsList}
                    repWorkApplication={repWorkApplication}
                    setRepWorkApplication={setRepWorkApplication}
                    repWorkApplicationError={{
                      repGlobalForm: {},
                      repSummerForm: {},
                      repWinterForm: {},
                    }}
                    setRepWorkApplicationError={null}
                    isWorkApplicationSubmitted={true}
                  ></RepBeachForm>
                  <RepWinterForm
                    jobFamiliesOptionsList={jobFamiliesOptionsList}
                    jobTitlesOptionsList={jobTitlesOptionsList}
                    repWorkApplication={repWorkApplication}
                    setRepWorkApplication={setRepWorkApplication}
                    repWorkApplicationError={{
                      repGlobalForm: {},
                      repSummerForm: {},
                      repWinterForm: {},
                    }}
                    setRepWorkApplicationError={null}
                    isWorkApplicationSubmitted={true}
                  />
                  <RepSummerForm
                    jobFamiliesOptionsList={jobFamiliesOptionsList}
                    jobTitlesOptionsList={jobTitlesOptionsList}
                    repWorkApplication={repWorkApplication}
                    setRepWorkApplication={setRepWorkApplication}
                    repWorkApplicationError={{
                      repGlobalForm: {},
                      repSummerForm: {},
                      repWinterForm: {},
                    }}
                    setRepWorkApplicationError={null}
                    isWorkApplicationSubmitted={true}
                  />
                </>
              )}

              <Card style={cardStyle}>
                <Card.Header>
                  <i className="fa fa-check mr-2" />
                  Submit form
                </Card.Header>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col xs={12} md={4}>
                        <Form.Group controlId="formSignature">
                          <Form.Label>Signature</Form.Label>
                          <Form.Control type="text" value={repWorkApplication?.repGlobalForm?.signature} />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group controlId="formLocation">
                          <Form.Label>Location {requiredField()}</Form.Label>
                          <Select
                            options={countriesOptionsList}
                            value={
                              countriesOptionsList.find(
                                (obj) => obj.value === repWorkApplication?.repGlobalForm?.location
                              ) || null
                            }
                            isClearable
                            isSearchable
                            isDisabled={true}
                          />
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Group controlId="formSubmitDate">
                          <Form.Label>Submit date {requiredField()}</Form.Label>
                          <Datetime
                            timeFormat={false}
                            dateFormat={DATE_FORMAT_WEB}
                            closeOnSelect
                            utc={true}
                            inputProps={{ placeholder: DATE_FORMAT_WEB, disabled: true }}
                            value={DateTimeFormater(
                              repWorkApplication?.repGlobalForm?.timestamp,
                              null,
                              DATE_FORMAT_WEB
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        <Form.Group controlId="formComments">
                          <Form.Label>Any other comments? {requiredField()}</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={8}
                            value={repWorkApplication?.repGlobalForm?.repComments}
                            disabled={true}
                            maxLength={100}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setIsShowRepFormModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default ManagerWorkApplicationForm;
